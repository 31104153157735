import React from "react";

export default function PageLink(props) {
  const { active, page_number, onPageChange } = props;

  return (
    <li
      className={`page-item ${active === "false" ? "active" : ""}`}
      onClick={() => {
        onPageChange(page_number);
      }}
    >
      <div className="page-link" disabled>
        {props.children}
      </div>
    </li>
  );
}
