import React, { useContext } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";
import apiService from "services/BackendAPI/BackendAPI";
import { useQuery } from "react-query";
import { SearchContext } from "modules/website/context/SearchContext/SearchContext";

export default function AudienceSelect(props) {
  const { onChangeSelect } = props;
  const { searchArgs } = useContext(SearchContext);

  const { data, isLoading } = useQuery("target-audiences", async () => {
    const response = await apiService.GetTargetAudiences();
    const data = response?.data?.data;
    if (Array.isArray(data)) {
      return data.map((audience) => {
        return {
          value: audience.glifos_target_audience_name,
          label: audience.glifos_target_audience_name,
        };
      });
    }
    return [];
  });

  const handleOnChange = (e) => {
    onChangeSelect(e, "targetaudience");
  };

  return (
    <InputGroup className="me-2">
      <Select
        onChange={handleOnChange}
        isLoading={isLoading}
        className="form-control"
        options={[{ label: "Audience", options: data }]}
        name="targetaudience"
        placeholder="Audience"
        value={
          data?.find(
            (element) => element.value === searchArgs?.targetaudience
          ) || null
        }
      />
    </InputGroup>
  );
}
