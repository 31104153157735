import { startLoading, loadLanguaje, endLoading } from "./languajeSlice";

export const siteLoadLanguaje = (languaje) => async (dispatch) => {
  await dispatch(startLoading());
  if (!languaje) {
    languaje = localStorage.getItem("gl-udp-languaje") ?? "en"; // * DEFAULT ENGLISH
  }

  localStorage.setItem("gl-udp-languaje", languaje);

  await dispatch(loadLanguaje({ languaje: languaje }));
  await dispatch(endLoading());
};
