import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullPageSpinner from "common/components/FullPageSpinner/FullPageSpinner";
import { siteLoadLanguaje } from "services/redux/languaje/languajeActions";
import { useLocation } from "react-router-dom";

export default function TranslatorContainer(props) {
  const dispatch = useDispatch();
  const languajeState = useSelector((state) => state.languaje);
  const location = useLocation();

  useEffect(() => {
    const loadLanguaje = async () => {
      const queryParams = new URLSearchParams(location.search);
      const lang = queryParams.get("sitelang");
      await dispatch(siteLoadLanguaje(lang));
    };
    if (!languajeState.isLoaded) {
      loadLanguaje();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {languajeState.isLoading && <FullPageSpinner />}
      {languajeState.isLoaded && <>{props.children}</>}
    </>
  );
}
