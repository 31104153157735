import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchWidget, {
  NODES,
} from "modules/website/components/SearchWidget/SearchWidget";
import styles from "./SearchTollkitView.module.scss";
import ToolkitHeader from "modules/website/components/ToolkitHeader/ToolkitHeader";
import apiService from "services/BackendAPI/BackendAPI";

export default function SearchTollkitView() {
  const site = useSelector((state) => state.site);
  const [active, setActive] = useState(0); // active toolkit
  const [audiences, setAudiences] = useState({ loading: true, error: null });
  const [currentAudience, setCurrentAudience] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const getAudiences = async () => {
      const response = await apiService.GetTargetAudiences();
      if (!response.data || !response?.date?.succces)
        setAudiences({
          loading: false,
          error: "It has not been possible to recover the master information",
        });

      setAudiences({
        loading: false,
        data: response?.data?.data,
      });
    };
    if (audiences.loading) getAudiences();
  }, []);

  useEffect(() => {
    const loadCurrent = () => {
      setCurrentAudience(null);
      const path = window.location.pathname;
      const toolkitList = site?.menu?.filter(
        (option) => option?.tid === "4"
      )?.[0];
      const lastSegment = path.substring(path.lastIndexOf("/") + 1);
      for (let i = 0; i < toolkitList?.childs.length; i++) {
        const element = toolkitList?.childs?.[i];
        const toolkitLastSegment = element?.field_url.substring(
          element?.field_url.lastIndexOf("/") + 1
        );
        if (toolkitLastSegment === lastSegment) {
          setActive(element.tid);
          setCurrentAudience(getCurrentTargetAudience());
          break;
        }
      }
    };
    if (!audiences.loading) loadCurrent();
  }, [location, audiences.loading]);

  const getCurrentTargetAudience = () => {
    const path = window.location.pathname;
    const lastSegment = path.substring(path.lastIndexOf("/") + 1);
    if (lastSegment === "toolkit-students")
      return audiences?.data?.filter(
        (el) => el.glifos_target_audience_id === 1
      )?.[0]?.glifos_target_audience_name;
    if (lastSegment === "toolkit-parents")
      return audiences?.data?.filter(
        (el) => el.glifos_target_audience_id === 2
      )?.[0]?.glifos_target_audience_name;
    if (lastSegment === "toolkit-teachers")
      return audiences?.data?.filter(
        (el) => el.glifos_target_audience_id === 3
      )?.[0]?.glifos_target_audience_name;
    if (lastSegment === "toolkit-community-leaders")
      return audiences?.data?.filter(
        (el) => el.glifos_target_audience_id === 4
      )?.[0]?.glifos_target_audience_name;
    return "";
  };

  return (
    <Container id="wrapper" className={`sm-layout-wrapper p-0`} fluid>
      <ToolkitHeader active={active} />
      <div className="row bg-white paddingPageSection py-2 g-0">
        <p className="text-center fw-bold">RESOURSES</p>
      </div>
      <div className={styles.arrowbottom} />
      <Row
        className={`d-flex align-items-center justify-content-center scrollSection paddingPageSectionX g-0 mb-4`}
      >
        <p className="text-center">
          Browse through this inspiring content (documents, videos and audios)
          that will help you acquire skills and attitudes about peace and
          positive values.
        </p>
        <div className="col-12">
          {audiences?.error && <p>{audiences.error}</p>}
          {currentAudience !== null && (
            <SearchWidget
              defaultSearchArgs={{
                targetaudience: currentAudience,
                rows: 8,
              }}
              type={NODES}
            />
          )}
        </div>
      </Row>
    </Container>
  );
}
