import React, { useContext, useState } from "react";
import { SearchContext } from "modules/website/context/SearchContext/SearchContext";
import { useGLTranslate } from "modules/website/hooks/useGLTranslate";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import AudienceSelect from "./AudienceSelect/AudienceSelect";
import LanguajeSelect from "./LanguajeSelect/LanguajeSelect";
import MediaTypeSelect from "./MediaTypeSelect/MediaTypeSelect";
import SortBySelect from "./SortBySelect/SortBySelect";
import { FaTrash } from "react-icons/fa6";
import styles from "./SearchFilters.module.scss";
import { Funnel } from "react-bootstrap-icons";

export default function SearchFilters() {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [Translate] = useGLTranslate();

  const { setSearchArgs, handleSearch, handleCleanResults, searchArgs } =
    useContext(SearchContext);

  //   const changeValue = (e) => {
  //     const previous_valus = searchArgs?.keywords;
  //     setSearchArgs((prev) => ({
  //       ...prev,
  //       keywords: e.target.value,
  //       page: 1,
  //     }));
  //     if (previous_valus?.length > 0 && e.target.value.trim() == "") {
  //       handleSearch(null, true);
  //     }
  //   };

  const onChangeSelect = (e, name) => {
    if (!name || !e?.value) return;
    setSearchArgs((prev) => ({ ...prev, [name]: e.value }));
    handleSearch(null, true);
  };

  const onChangeInput = (e) => {
    setSearchArgs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    handleSearch(null, true);
  };

  const handleClose = () => setShowFilterModal(false);
  const handleShow = () => setShowFilterModal(true);

  return (
    <>
      <Form
        className={`d-flex w-100 ${styles.form} d-none d-md-flex`}
        onSubmit={handleSearch}
      >
        <InputGroup className="me-2">
          <InputGroup.Text id="date">Date</InputGroup.Text>
          <Form.Control
            name="date"
            type="date"
            placeholder="Date"
            aria-label="Date"
            aria-describedby="date"
            onChange={onChangeInput}
            value={searchArgs?.date || ""}
          />
        </InputGroup>
        <MediaTypeSelect onChangeSelect={onChangeSelect} />
        <AudienceSelect onChangeSelect={onChangeSelect} />
        <LanguajeSelect onChangeSelect={onChangeSelect} />
        <InputGroup className="ms-2">
          <Button onClick={handleCleanResults}>
            <FaTrash /> Clear
          </Button>
        </InputGroup>
        <SortBySelect onChangeSelect={onChangeSelect} />
      </Form>
      <Button
        variant="secondary"
        onClick={handleShow}
        className="d-block d-md-none"
      >
        <Funnel /> Filtros
      </Button>
      <Modal
        show={showFilterModal}
        onHide={handleClose}
        style={{ zIndex: 1200 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Filtros de busqueda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className={`d-flex w-100 ${styles.form} row`}
            onSubmit={handleSearch}
          >
            <div className="col-12 mb-2 me-2">
              <InputGroup>
                <InputGroup.Text id="date">Date</InputGroup.Text>
                <Form.Control
                  name="date"
                  type="date"
                  placeholder="Date"
                  aria-label="Date"
                  aria-describedby="date"
                  onChange={onChangeInput}
                  value={searchArgs?.date || ""}
                />
              </InputGroup>
            </div>
            <div className="col-12 mb-2">
              <MediaTypeSelect onChangeSelect={onChangeSelect} />
            </div>
            <div>
              <AudienceSelect onChangeSelect={onChangeSelect} />
            </div>
            <div className="col-12 mb-2">
              <LanguajeSelect onChangeSelect={onChangeSelect} />
            </div>
            <div className="col-12 mb-2">
              <SortBySelect onChangeSelect={onChangeSelect} />
            </div>
            <div className="col-12 mb-2">
              <Button onClick={handleCleanResults}>
                <FaTrash /> Clear
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
