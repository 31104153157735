import SearchWidget from "modules/website/components/SearchWidget/SearchWidget";
import ToolkitHeader from "modules/website/components/ToolkitHeader/ToolkitHeader";
import React from "react";
import { Container, Row } from "react-bootstrap";

export default function SearchView() {
  return (
    <Container id="wrapper" className={`sm-layout-wrapper p-0`} fluid>
      <ToolkitHeader />
      <Row
        className={`d-flex align-items-center justify-content-center scrollSection paddingPageSection g-0`}
      >
        <SearchWidget
          defaultSearchArgs={{
            rows: 12,
          }}
          withFilters={true}
        />
      </Row>
    </Container>
  );
}
