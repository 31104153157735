import { createSlice } from "@reduxjs/toolkit";

export const LANGUAJE_MAPS = {
  en: "",
  rw: "rw",
};

export const initialState = {
  languaje: null,
  isLoading: false,
  isLoaded: false,
};

const siteSlice = createSlice({
  name: "languaje",
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
      state.isLoaded = true;
    },
    loadLanguaje: (state, action) => {
      state.languaje = action.payload?.languaje;
    },
  },
});

export const { startLoading, endLoading, loadLanguaje } = siteSlice.actions;
export default siteSlice.reducer;
