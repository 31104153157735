import React from "react";
import { Button } from "react-bootstrap";
import NavbarOffcanvas from "react-bootstrap/esm/NavbarOffcanvas";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RiCloseLargeLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { IoMdLogIn } from "react-icons/io";
import engFlag from "assets/img/lg_en.png";
import rwandaFlag from "assets/img/rwandaflag.png";
import { decodeHtmlEntities } from "common/helpers/DataFormat";
import { LANGUAJE_MAPS } from "services/redux/languaje/languajeSlice";
import { useSelector } from "react-redux";
import { useGLTranslate } from "modules/website/hooks/useGLTranslate";
import OnlySearchBar from "modules/website/components/OnlySearchBar/OnlySearchBar";

export default function SideMenu(porps) {
  const { variant, handleCloseOffCanvas, offcanvasOpen } = porps;
  const site = useSelector((state) => state.site);
  const [Translate] = useGLTranslate();

  return (
    <NavbarOffcanvas
      placement="end"
      id="offcanvasWithBothOptions"
      aria-labelledby="offcanvasWithBothOptionsLabel"
      className={`offcanvas offcanvas-${variant} bg-${variant}`}
      show={offcanvasOpen}
      data-bs-theme={variant}
    >
      <Offcanvas.Body className="d-flex flex-column px-2 w-100">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center justify-content-center text-uppercase">
            {Translate("search")}
          </div>
          <Button
            onClick={handleCloseOffCanvas}
            className="fs-6"
            variant="primary"
          >
            <RiCloseLargeLine />
          </Button>
        </div>
        <div className="mb-3">
          <OnlySearchBar />
        </div>
        <p className="text-uppercase">{Translate("lSelect")}</p>
        <div className="mt-1 mb-1">
          <a href={`?sitelang=${Object.keys(LANGUAJE_MAPS)?.[0]}`}>
            <div className="cursor-pointer btn btn-ligth">
              <img alt="eng" src={engFlag} width={30} />
              <span className="ms-2 text-uppercase">English</span>
            </div>
          </a>
        </div>
        <div className="mt-1 mb-1">
          <a href={`?sitelang=${Object.keys(LANGUAJE_MAPS)?.[1]}`}>
            <div className="cursor-pointer btn btn-ligth">
              <img alt="eng" src={rwandaFlag} width={30} />
              <span className="ms-2 text-uppercase">Kinyarwanda</span>
            </div>
          </a>
        </div>
        <div className="mt-2 px-2">
          <Link
            to="https://ubumuntu.rw/lms/"
            className="text-uppercase text-decoration-none"
          >
            <IoMdLogIn className="fs-3" /> <span>{Translate("login")}</span>
          </Link>
        </div>
        <hr className="custom-hr mb-2" />
        {site?.menu?.map((link, idx) => {
          return (
            <div key={idx}>
              <Link
                to={`${link?.field_url}`}
                className="text-uppercase text-decoration-none"
                onClick={handleCloseOffCanvas}
              >
                {decodeHtmlEntities(link?.name)}
              </Link>
            </div>
          );
        })}
        <p className="mt-3">{Translate("ulinks")}:</p>
        {site?.userfulLinks?.map((link, idx) => {
          return (
            <div key={idx}>
              <Link
                to={link?.field_url || "#"}
                className="text-uppercase text-decoration-none"
                onClick={handleCloseOffCanvas}
              >
                {link?.name}
              </Link>
            </div>
          );
        })}
        <div className="mt-2">
          <div>
            <Link
              to="/terms-conditions"
              className="text-uppercase text-decoration-none"
              onClick={handleCloseOffCanvas}
            >
              {Translate("termscontitios")}
            </Link>
          </div>
          <div>
            <Link
              to="/privacy"
              className="text-uppercase text-decoration-none"
              onClick={handleCloseOffCanvas}
            >
              {Translate("privpolicy")}
            </Link>
          </div>
        </div>
      </Offcanvas.Body>
    </NavbarOffcanvas>
  );
}
