import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoading: false,
  isLoaded: false,
  userfulLinks: [],
  error: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
    failLoaded: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload?.error;
    },
    loadLanguaje: (state, action) => {
      state.languaje = action.payload?.languaje;
    },
    loadMenu: (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.menu = action.payload?.menu;
      state.userfulLinks = action.payload?.userfulLinks;
    },
  },
});

export const { startLoading, endLoading, failLoaded, loadMenu, loadLanguaje } =
  siteSlice.actions;
export default siteSlice.reducer;
