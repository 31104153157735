import React from "react";
import PageLink from "../PageLink/PageLink";

export default function NextPrevPage(props) {
  const { type = "Next", page_number, onPageChange } = props;

  if (!page_number || page_number === "null") return <></>;

  return (
    <PageLink type={type} onPageChange={onPageChange} page_number={page_number}>
      <span aria-hidden="true">
        {type === "Previous" ? <>&laquo;</> : <>&raquo;</>}
      </span>
    </PageLink>
  );
}
