import { SearchContext } from "modules/website/context/SearchContext/SearchContext";
import React, { useContext } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";

export default function SortBySelect(props) {
  const { onChangeSelect } = props;
  const { searchArgs } = useContext(SearchContext);

  const data = [
    { label: "Date", value: "date" },
    { label: "Title", value: "sorttitle" },
    { label: "Media Type", value: "typemedia" },
  ];

  const handleOnChange = (e) => {
    onChangeSelect(e, "sortby");
  };

  return (
    <InputGroup className="ms-2">
      <Select
        onChange={handleOnChange}
        className="form-control"
        options={[{ label: "Sort by", options: data }]}
        name="sortby"
        placeholder="Sort by"
        styles={{ zIndex: "100" }}
        value={
          data?.find((element) => element.value === searchArgs?.sortby) || null
        }
      />
    </InputGroup>
  );
}
