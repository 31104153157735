import { SearchContext } from "modules/website/context/SearchContext/SearchContext";
import React, { useContext } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";

export default function MediaTypeSelect(props) {
  const { onChangeSelect } = props;
  const { searchArgs } = useContext(SearchContext);

  const data = [
    { label: "Text document", value: "txt" },
    { label: "Video", value: "video" },
    { label: "Audio", value: "audio" },
  ];

  const handleOnChange = (e) => {
    onChangeSelect(e, "typemedia");
  };

  return (
    <InputGroup className="me-2">
      <Select
        onChange={handleOnChange}
        className="form-control"
        options={[{ label: "Media Type", options: data }]}
        name="typemedia"
        placeholder="Media Type"
        value={
          data?.find((element) => element.value === searchArgs?.typemedia) ||
          null
        }
      />
    </InputGroup>
  );
}
