import React from "react";
import fallback from "assets/img/resultNotFound.png";
import { FaArrowRight } from "react-icons/fa6";
import { COMMUNITY_PROJECTS, NEWS, NODES, PEACE_CLUBS } from "../SearchWidget";
import styles from "./SearchRecord.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Card, Placeholder } from "react-bootstrap";
import "./SearchRecord.scss";
import { formatDateToString } from "common/helpers/DataFormat";

export default function SearchRecord(props) {
  const { thumbnail, title, type, url } = props;

  const removeUnderscore = (content) => {
    if (!content) return "";
    return content.replace("_", " ");
  };

  if (type === COMMUNITY_PROJECTS || type === PEACE_CLUBS) {
    return (
      <div className={`col-12 col-md-3 mb-3 ${styles.cardpading}`}>
        <Link to={`${url}`} className="text-black text-decoration-none">
          <Card className={`${styles.recordCommunity} hover${type}`}>
            <Card.Header className={styles.cardHeader}>
              <LazyLoadImage
                className={`card-img-top ${styles.cardImg} mb-2`}
                alt={title}
                src={thumbnail} // use normal
                effect="blur"
                delayTime={1}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallback;
                }}
              />
            </Card.Header>
            <Card.Body className="p-1">
              <Card.Title className={styles.recordTitle}>{title}</Card.Title>
            </Card.Body>
            <Card.Footer className={`${styles.footer}`}>
              <FaArrowRight />
            </Card.Footer>
          </Card>
        </Link>
      </div>
    );
  }

  if (type === NEWS) {
    return (
      <div className={`col-12 col-md-4 mb-3 ${styles.cardpading}`}>
        <Link to={`${url}`} className="text-black text-decoration-none">
          <Card
            className={`${styles.record} hover-${
              props?.node_type === "NEWS" ? "warning" : "primary"
            }`}
          >
            <Card.Header className={styles.cardHeader}>
              <LazyLoadImage
                className={`card-img-top ${styles.cardImg} mb-2`}
                alt={title}
                src={thumbnail} // use normal
                effect="blur"
                delayTime={1}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallback;
                }}
              />
            </Card.Header>
            <Card.Body className="p-1">
              <Card.Title className={styles.recordTitle}>{title}</Card.Title>
            </Card.Body>
            <Card.Footer className={`${styles.footer} row`}>
              <div className="col">
                <h6
                  className={`fw-bold text-uppercase text-${
                    props?.contenttype === "news" ? "warning" : "primary"
                  }`}
                >
                  {removeUnderscore(props?.contenttype)}
                </h6>
                <p className="m-0 p-0 text-muted">
                  {formatDateToString(props?.date)}
                </p>
              </div>
              {!Array.isArray(props?.creator) > 0 && (
                <p className="col m-0 p-0 text-muted mt-auto text-end">
                  {props.creator}
                </p>
              )}
            </Card.Footer>
          </Card>
        </Link>
      </div>
    );
  }

  const currentDate = new Date().toISOString().split("T")[0];

  const truncateString = (str) => {
    const n = 70;
    return str.length > n ? str.slice(0, n) + "..." : str;
  };

  if (type === NODES) {
    return (
      <div className={`col-12 col-md-3 mb-3 ${styles.cardpading}`}>
        <Link to={`${url}`} className="text-black text-decoration-none">
          <Card className={`${styles.record}`}>
            {props?.date && (
              <Card.Header className={`${styles.header} text-uppercase`}>
                {formatDateToString(props?.date) ||
                  formatDateToString(currentDate)}
              </Card.Header>
            )}
            <LazyLoadImage
              className={`${styles.cardImg} mb-2`}
              alt={title}
              src={thumbnail} // use normal
              effect="blur"
              delayTime={1}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = fallback;
              }}
            />
            <Card.Body className="p-1">
              <Card.Title className={styles.recordTitle}>{title}</Card.Title>
              <p className={styles.description}>
                {truncateString(props.description)}
              </p>
            </Card.Body>
            <Card.Footer className={`${styles.footer}`}>
              <FaArrowRight />
            </Card.Footer>
          </Card>
        </Link>
      </div>
    );
  }

  return (
    <div className={`col-12 col-md-3 mb-3 ${styles.cardpading}`}>
      <Card className="cisadgccrd cardLoading px-2 py-2">
        <Placeholder.Button
          className="waitImage"
          variant="secondary"
          xs={12}
          style={{ height: "100px" }}
        />
        <Card.Body className="bodyCard">
          <Card.Title>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4} /> <Placeholder xs={7} />
              <Placeholder xs={12} />
            </Placeholder>
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}
