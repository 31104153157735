import { SearchContext } from "modules/website/context/SearchContext/SearchContext";
import React, { useContext } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";

export default function LanguajeSelect(props) {
  const { onChangeSelect } = props;
  const { searchArgs } = useContext(SearchContext);

  const data = [
    { label: "English", value: "en" },
    { label: "Kinyarwanda", value: "kinyarwanda" },
  ];

  const handleOnChange = (e) => {
    onChangeSelect(e, "lang");
  };

  return (
    <InputGroup className="me-2">
      <Select
        onChange={handleOnChange}
        className="form-control"
        options={[{ label: "Language", options: data }]}
        name="lang"
        placeholder="Language"
        value={
          data?.find((element) => element.value === searchArgs?.lang) || null
        }
      />
    </InputGroup>
  );
}
