import { configureStore } from "@reduxjs/toolkit";
import siteReducer from "services/redux/site/SiteSlice";
import languajeReducer from "services/redux/languaje/languajeSlice";

export const store = configureStore({
  reducer: {
    site: siteReducer,
    languaje: languajeReducer,
  },
});
