import React, { useEffect, useState } from "react";
import Pagination from "./Pagination/Pagination";
import SearchRecord from "./SearchRecord/SearchRecord";
import { SearchContext } from "modules/website/context/SearchContext/SearchContext";
import apiService from "services/BackendAPI/BackendAPI";
import SearchBar from "modules/website/components/SearchWidget/SearchBar/SearchBar";
import NodeCategoriesDisplay from "../NodeCategoriesDisplay/NodeCategoriesDisplay";
import PopularArticles from "../PopularArticles/PopularArticles";
import SearchFilters from "./SearchFilters/SearchFilters";

export const COMMUNITY_PROJECTS = "community_projects";
export const PEACE_CLUBS = "peace_clubs";
export const NEWS = "news";
export const NODES = 3;

export default function SearchWidget(props) {
  const {
    type = NODES,
    defaultSearchArgs,
    advanced = false, // to display popular articles and categories
    hideFirstResults = false, // default hide searsh result
    withFilters = false, // to display search bar filters
    className,
  } = props;

  const [searchResults, setSearchResults] = useState({
    records: [],
    result_header: {},
    loading: true,
    update: false,
  });
  const [hideResults, setHideResults] = useState(hideFirstResults);
  const [searchArgs, setSearchArgs] = useState({});

  // dispara el cambio para que el useEfect actualize la busqueda
  const reFetch = () => {
    setHideResults(false);
    setSearchResults({ ...searchResults, update: true });
  };

  const handleSearch = (e, pagination = false) => {
    if (pagination) return reFetch();

    e.preventDefault();
    return submitForm(e.target.elements);
  };

  const handleCleanResults = () => {
    if (searchArgs === defaultSearchArgs) return;
    setSearchArgs(defaultSearchArgs);
    reFetch();
  };

  const submitForm = (elements) => {
    const params = Array.from(elements)
      .filter((item) =>
        ["input", "select"].includes(item.tagName.toLowerCase())
      ) // Filter only input and select elements
      .map((input) => ({ name: input.name, value: input.value })) // Format input values in json
      .filter((item) => item.name !== ""); // Remove blank inputs // Remove duplicate

    const objParams = Object.fromEntries(
      params.map((param) => [param.name, param.value])
    );
    const newArgs = { ...searchArgs, ...objParams };
    setSearchArgs(newArgs);
    reFetch();
  };

  // fetch de busqueda
  useEffect(() => {
    const fetchDate = async () => {
      await setSearchResults((prev) => ({ ...prev, loading: true }));
      const newArgs = { ...defaultSearchArgs, ...searchArgs };
      const newParams = new URLSearchParams(newArgs);
      window.history.pushState({}, "", `?${newParams.toString()}`);
      const response = await apiService.GetSearchQuery({ params: newArgs });

      if (!response?.data || !response?.data?.success)
        return setSearchResults({
          records: [],
          result_header: {},
          loading: false,
        });

      let records = response?.data?.records?.record;
      if (!records) records = [];
      if (!Array.isArray(records)) records = [records];

      return setSearchResults({
        records: records,
        result_header: response?.data?.result_header,
        loading: false,
      });
    };
    if (searchResults.update && !searchResults.loading) fetchDate();
  }, [searchArgs, searchResults.update]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paramsObject = Object.fromEntries(urlSearchParams.entries());
    setSearchArgs({ ...defaultSearchArgs, ...paramsObject });
    setSearchResults((prev) => ({ ...prev, loading: false, update: true }));
  }, []);

  useEffect(() => {
    if (hideFirstResults) {
      setHideResults(hideFirstResults);
    }
  }, [props.children]);

  return (
    <SearchContext.Provider
      value={{
        handleSearch: handleSearch,
        handleCleanResults: handleCleanResults,
        searchArgs: searchArgs,
        setSearchArgs: setSearchArgs,
      }}
    >
      {hideResults && <>{props.children}</>}
      {!hideResults && (
        <div className={className ? className : ""}>
          <div className="row g-0 justify-content-center align-items-center mb-4 sm-padding-05">
            <div className="d-flex col-12 col-md-4">
              <SearchBar />
            </div>
          </div>
          {withFilters && (
            <div className="row g-0 justify-content-center align-items-center mb-4 sm-padding-05">
              <div className="col">
                <SearchFilters />
              </div>
            </div>
          )}
          <div className="row g-0">
            <Pagination result_header={searchResults?.result_header} />
            {searchResults.loading && (
              <div className="row g-0 mt-2">
                {Array.from({ length: 8 }, (_, i) => i + 1).map((_, idx) => {
                  return <SearchRecord key={idx} />;
                })}
              </div>
            )}
            {!searchResults.loading && (
              <div className="row g-0 sm-padding-05 mt-2">
                {searchResults?.records?.length < 1 && (
                  <p className="mt-3 text-center">No results found.</p>
                )}
                {searchResults?.records?.map((record, idx) => {
                  return <SearchRecord type={type} key={idx} {...record} />;
                })}
              </div>
            )}
            <Pagination result_header={searchResults?.result_header} />
          </div>
        </div>
      )}
      {advanced && (
        <div className="col-12 col-md-3 px-md-4 sm-padding-05">
          <div className="mb-3">
            <NodeCategoriesDisplay />
          </div>
          <div className="mb-3">
            <p className="text-uppsercase">SEARCH</p>
            <SearchBar />
          </div>
          <PopularArticles />
        </div>
      )}
    </SearchContext.Provider>
  );
}
