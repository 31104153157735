import { SearchContext } from "modules/website/context/SearchContext/SearchContext";
import { useGLTranslate } from "modules/website/hooks/useGLTranslate";
import React, { useContext } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

export default function SearchBar() {
  const [Translate] = useGLTranslate();

  const { setSearchArgs, handleSearch, searchArgs } = useContext(SearchContext);

  const changeValue = (e) => {
    const previous_valus = searchArgs?.keywords;
    setSearchArgs((prev) => ({
      ...prev,
      keywords: e.target.value,
      page: 1,
    }));
    if (previous_valus?.length > 0 && e.target.value.trim() == "") {
      handleSearch(null, true);
    }
  };

  return (
    <Form className={`d-flex w-100`} onSubmit={handleSearch}>
      <InputGroup>
        <Form.Control
          type="search"
          placeholder={Translate("searchLabel")}
          aria-label={Translate("searchLabel")}
          onChange={changeValue}
          value={searchArgs?.keywords || ""}
        />
        <InputGroup.Text id="basic-addon1">
          <FaSearch />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
}
