import ImageWithFallback from "common/components/ImageWithFallback/ImageWithFallback";
import React from "react";
import { Link } from "react-router-dom";
import falback from "assets/img/fallbackImage.jpg";

const popular = [
  {
    nid: 12,
    title: "Aegis Trust Receives the Raphael Lemkin International Award",
    img: "https://www.ubumuntu.rw/sites/default/files/styles/large/public/2019-03/pruebas.jpg?itok=M9g94wNG",
  },
  {
    nid: 65,
    title: "Aegis equips Rwandan teachers to be local peace ambassadors",
    img: "https://ubumuntu.rw/sites/default/files/styles/large/public/2018-08/Aegis%20equips%20Rwandan%20teachers%20to%20be%20local%20peace%20ambassadors.jpg?itok=pLHdHyzB",
  },
  {
    nid: 88,
    title: "Aegis presents Rwanda Education Board with new resources",
    img: "https://ubumuntu.rw/sites/default/files/styles/large/public/2018-08/aegis_1-c5917-4970f.jpg?itok=6k-RgPex",
  },
  {
    nid: 111,
    title:
      "Aegis launches Karongi Peace School, a Resource Centre for Peace Education in Rwanda",
    img: "https://ubumuntu.rw/sites/default/files/styles/large/public/2018-08/Aegis%20launches%20Karongi%20Peace%20School%202.jpg?itok=HZevmgkz",
  },
];

export default function PopularArticles() {
  return (
    <div className="">
      <p className="test-uppercas mb-2">PopularArticles</p>
      {popular?.map((element, idx) => {
        return (
          <Link
            to={`/node/${element.nid}`}
            key={idx}
            className="text-decoration-none"
          >
            <div className="d-flex row mb-3">
              <div className="col-4">
                <ImageWithFallback
                  fallback={falback}
                  src={element.img}
                  className="donor_img"
                />
              </div>
              <div className="col text-black" style={{ fontSize: "10pt" }}>
                {element.title}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
