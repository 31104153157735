import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiService from "services/BackendAPI/BackendAPI";
import { LANGUAJE_MAPS } from "services/redux/languaje/languajeSlice";

const metadataNode = [
  "ubumuntu_lesson",
  "ubumuntu_model_lesson_plan",
  "ubumuntu_archive",
  "ubumuntu_rich_media",
];

export function useNidNodePage(props) {
  const { nid, stylesheet = "xml2Json" } = props;
  const [pageContent, setPageContent] = useState({
    isLoading: false,
    isError: false,
    payload: null,
    error: null,
    type: null,
  });

  // GET THE LANGUAJE OF THE SITE
  const languajeState = useSelector((state) => state.languaje);
  const languaje = LANGUAJE_MAPS[languajeState.languaje];
  // GET THE LANGUAJE OF THE SITE

  useEffect(() => {
    const fetchData = async () => {
      setPageContent((prev) => ({ ...prev, isLoading: true }));

      try {
        const typeResponse = await apiService.GetPageType({
          urlParams: { alias: nid },
        });
        if (!typeResponse.data) throw new Error("Node type not found.");

        let response = null;
        // NODES WHIT METADATA
        if (metadataNode.includes(typeResponse.data?.type)) {
          response = await apiService.GetNodeMetadata({
            urlParams: { nid: nid, stylesheet: stylesheet },
          });
        } else {
          response = await apiService.GetPageInfo({
            urlParams: { alias: nid, languaje: languaje },
            params: { _format: "json" },
          });
        }

        setPageContent((prev) => ({
          ...prev,
          isError: false,
          payload: { ...response.data, type: typeResponse.data?.type },
          isLoading: false,
        }));
      } catch (error) {
        setPageContent((prev) => ({
          ...prev,
          isError: true,
          error: error,
          isLoading: false,
        }));
      }
    };

    fetchData();
  }, [nid, stylesheet]);

  return pageContent;
}
