import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { siteLoadMenu } from "services/redux/site/siteActions";
import styles from "common/layouts/SideMenuTopBarLayout/SideMenuTopBarLayout.module.scss";
import Footer from "common/layouts/SideMenuTopBarLayout/Footer/Footer";
import FullPageSpinner from "common/components/FullPageSpinner/FullPageSpinner";
import SideMenuTopBar from "common/layouts/SideMenuTopBarLayout/SideMenuTopBar/SideMenuTopBar";

export default function SideMenuTopBarLayout(props) {
  const { className, variant = "dark" } = props;
  const dispatch = useDispatch();
  const site = useSelector((state) => state.site);
  const languajeState = useSelector((state) => state.languaje);

  useEffect(() => {
    const loadMenuOptions = async () => {
      await dispatch(siteLoadMenu(languajeState.languaje));
    };
    if (!site?.menu) {
      loadMenuOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY || window.pageYOffset;
    // || variant === "transparent" && scrollPosition > 20
    if (scrollPosition > 160) {
      const scrollSections = document.querySelectorAll(".scrollSection");
      scrollSections.forEach((section) => {
        section.classList.remove("scrollSection");
        section.classList.add("scrollSectionHiden");
      });
    } else if (scrollPosition < 10) {
      const scrollSections = document.querySelectorAll(".scrollSectionHiden");
      scrollSections.forEach((section) => {
        section.classList.remove("scrollSectionHiden");
        section.classList.add("scrollSection");
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container
      className={`sm-layout-wrapper ${className} g-0 ${styles.container}`}
      fluid
    >
      {site?.isLoading && <FullPageSpinner />}
      {site?.error && (
        <div className="d-flex vh-100 align-items-center justify-content-center">
          <p className="alert alert-danger">{site?.error}</p>
        </div>
      )}
      {site?.isLoaded && (
        <>
          <Row className={"g-0"}>
            <Col xs={true} className={styles.pagecontentcolumn}>
              <div className={`scrollSection`} id="SideMenuTopBarSection">
                <SideMenuTopBar variant={variant} />
              </div>
              <div className={styles.pagecontentwrapper}>{props.children}</div>
            </Col>
          </Row>
          <Footer />
        </>
      )}
    </Container>
  );
}
