import { useSelector } from "react-redux";
import { gT } from "translate/translator";

export const useGLTranslate = () => {
  const languajeState = useSelector((state) => state.languaje);
  const Translate = (text) => {
    return gT(languajeState.languaje, text);
  };

  return [Translate];
};
