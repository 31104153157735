// La conexión la diferencia dependiendo el ambiente en el que esté
// const ApiHostName =
// 	window.location.hostname.indexOf('texasarchive.org') >= 0
// 		? window.location.hostname + '/api'
// 		: window.location.hostname.indexOf('local') >= 0
// 		? window.location.hostname + '/api'
// 		: 'api.tami.dev.glifos.net';

export const ApiHostName = "ubumuntu.dev.glifos.net";
export const YiiApiURL = `https://${ApiHostName}/api/v1/`;
export const FilesURL = `${YiiApiURL}file/get`;
export const DownloadURL = `https://${ApiHostName}/api/glifos/glifos/file/download`;

//console.log('ApiHostName:' + ApiHostName);
const ApiURLs = {
  publicFilesURL: "https://" + ApiHostName + "/v1/",
  //   baseURL: window.location.protocol + "//" + ApiHostName,
  baseURL: "https:" + "//" + ApiHostName,

  //para pruebas en telefono
  /* publicFilesURL: 'https://' + '192.168.31.82' + '/v1/',
	baseURL: window.location.protocol + '//' + '192.168.31.82/api', */

  GetMenu: {
    href: "/api/glifos/:languaje/api/menu",
    method: "get",
    urlParams: ["languaje"],
  },
  GetUserfulLinks: {
    href: "/api/glifos/:languaje/api/userful-links",
    method: "get",
    urlParams: ["languaje"],
  },
  GetPageInfo: {
    href: "/api/glifos/:languaje/node/:alias",
    method: "get",
    urlParams: ["alias", "languaje"],
    params: ["_format"],
  },
  GetPageType: {
    href: "/api/v1/node/type/:alias",
    method: "get",
    urlParams: ["alias"],
  },
  GetNodeInfo: {
    href: "/api/glifos/:languaje/node/:nid",
    method: "get",
    urlParams: ["nid", "languaje"],
    params: ["_format"],
  },
  GetNodeMetadata: {
    href: "/api/v1/node/metadata/:nid/:stylesheet",
    method: "get",
    urlParams: ["nid", "stylesheet"],
  },
  GetSearchQuery: {
		href: '/api/v1/search/website-query',
		method: 'get',
		params: [
		],
	},
  GetTargetAudiences: {
		href: '/api/v1/masters/get-audiences',
		method: 'get',
		params: [
		],
	},
};
//console.log(ApiURLs);
export default ApiURLs;
