import React from "react";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import styles from "./ToolkitHeader.module.scss";
import { useSelector } from "react-redux";
import { decodeHtmlEntities } from "common/helpers/DataFormat";

const ToolkitHeader = (props) => {
  const { active } = props;
  const site = useSelector((state) => state.site);
  const toolkitList = site?.menu?.filter((option) => option?.tid === "4")?.[0];

  return (
    <>
      <div className={`row bg-alternative g-0 ${styles.top}`}>
        <h1 className="col-2 text-center text-uppercase">
          {toolkitList?.name}
        </h1>
        <div className="d-none d-md-flex col-9 row g-0 px-2">
          {toolkitList?.childs?.map((link, idx) => {
            return (
              <a
                href={link?.field_url}
                key={idx}
                className={`${
                  styles?.toolkit
                } col text-center d-none d-md-flex mx-2 ${
                  link?.tid === active ? styles.currentTol : ""
                }`}
              >
                <span>{decodeHtmlEntities(link?.name)}</span>
              </a>
            );
          })}
        </div>
        <div
          className={`col-1 d-none d-md-flex ${styles?.searchBtn} d-flex justify-content-center`}
        >
          <Link to="/search/page">
            <div className="py-2 px-3">
              <IoSearch />{" "}
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ToolkitHeader;
