import React, { useContext } from "react";
import NextPrevPage from "./NextPrevPage/NextPrevPage";
import PageLink from "./PageLink/PageLink";
import { SearchContext } from "modules/website/context/SearchContext/SearchContext";

const Pagination = (porps) => {
  const { result_header } = porps;
  const { setSearchArgs, handleSearch } = useContext(SearchContext);

  const onPageChange = async (number) => {
    if (!number) return;
    await setSearchArgs((prev) => ({ ...prev, page: number }));
    handleSearch(null, true);
  };

  return (
    <div className="d-flex justify-content-between sm-padding-05">
      <div className="d-flex flex-column justify-content-center ms-1">
        {result_header?.total_results !== "NaN"
          ? result_header?.total_results?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "0"}{" "}
        results
      </div>
      {result_header?.total_results !== "NaN" &&
        result_header?.total_results > 0 && (
          <nav>
            <ul className="pagination m-0">
              <NextPrevPage
                page_number={result_header?.pagination?.previous_page}
                type="Previous"
                onPageChange={onPageChange}
              />

              {result_header?.pagination?.page_list &&
                Array.isArray(result_header?.pagination?.page_list) &&
                result_header?.pagination?.page_list?.map((page, i) => (
                  <PageLink key={i} {...page} onPageChange={onPageChange}>
                    {page?.page_number}
                  </PageLink>
                ))}
              <NextPrevPage
                page_number={result_header?.pagination?.next_page}
                onPageChange={onPageChange}
                type="Next"
              />
            </ul>
          </nav>
        )}
    </div>
  );
};

export default Pagination;
