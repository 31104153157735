import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import brand from "assets/img/logo.png";
import aegis from "assets/img/aegis.png";
import { Button } from "react-bootstrap";
import { decodeHtmlEntities } from "common/helpers/DataFormat";
import { useSelector } from "react-redux";
import "./Topbar.scss";

export default function Topbar(porps) {
  const { variant, activeOffCanvas } = porps;
  const site = useSelector((state) => state.site);
  return (
    <Navbar
      expand="xl"
      bg={variant}
      data-bs-theme={variant}
      className={`px-2 mainNav navbar-${variant}`}
    >
      <Container fluid>
        <Navbar.Brand href="/" className="d-inline-block align-top me-2 py-1">
          <img alt="band" className="bandImage" src={brand} /> UBUMUNTU
        </Navbar.Brand>
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="me-auto">
            {site?.menu?.map((link, idx) => {
              if (link?.childs) {
                return (
                  <NavDropdown
                    key={idx}
                    title={decodeHtmlEntities(link?.name)}
                    id={`basic-nav-${decodeHtmlEntities(link?.name)}`}
                    className="nav-item"
                    renderMenuOnMount={true}
                    onClick={(e) => {
                      console.log(e);
                    }}
                  >
                    {link?.childs?.map((element, idx) => {
                      return (
                        <NavDropdown.Item
                          key={idx}
                          href={`${element?.field_url}`}
                        >
                          {decodeHtmlEntities(element?.name)}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                );
              }
              return (
                <Nav.Link key={idx} href={`${link?.field_url}`}>
                  {decodeHtmlEntities(link?.name)}
                </Nav.Link>
              );
            })}
          </Nav>
          <Nav className="d-inline-block align-top py-1 px-2">
            <Navbar.Text>
              <img alt="" src={aegis} className="aegis" />
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
        <Button variant={variant} className="me-4" onClick={activeOffCanvas}>
          <FaBars style={{ fontSize: "2rem" }} />
        </Button>
      </Container>
    </Navbar>
  );
}
